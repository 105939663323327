import { useEffect, useContext, useState, createContext } from 'react'

export const LiffContext = createContext()
export const useLiff = () => useContext(LiffContext)
export const LiffProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [liff, setLiff] = useState()

   useEffect(() => {
       const initLiff = async () => {
            const liff = (await import('@line/liff')).default

            if (!process.env.NEXT_PUBLIC_LIFF_ID) throw new Error("NEXT_PUBLIC_LIFF_ID が未定義")
            await liff.init({ liffId: process.env.NEXT_PUBLIC_LIFF_ID })
            await liff.ready
            setLoading(false)
            setLiff(liff)
       }
       initLiff()
    }, [])

    return (
        <LiffContext.Provider value={{liff, loading}}>
            {children}
        </LiffContext.Provider>
    )
}
