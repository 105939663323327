import Head from 'next/head'
import Link from 'next/link'
import Layout, { siteTitle } from '../components/layout'
import { getSortedPostsData } from '../lib/posts'
import { useAuth0 } from '@auth0/auth0-react'
import { useLiff, LiffProvider } from '../components/line'
import { useEffect, useState } from 'react'

export async function getStaticProps() {
  const allPostsData = getSortedPostsData()
  return {
    props: {
      allPostsData
    }
  }
}

function MyButton(props) {
  return (
    <button className={`mx-2 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded ${props.disabled ? 'opacity-50 cursor-not-allowed' : '' }`} {...props}>
      {props.children}
    </button>
  )
}

function LoginAndLogout({ label, name, note, loading, data, children }) {
  return (
    <section className="w-full my-2 inline-flex items-center justify-between bg-white p-2 shadow">
      <p>{label}:{loading ? '読込中' : name}{note}</p>
      {/* <p>{JSON.stringify(data, null, 2)}</p> */}
      <div>
        {children}
      </div>
    </section>
  )
}

function LIFFStatus() {
  const [profile, setProfile] = useState()
  const { liff, loading } = useLiff()

  useEffect(() => {
    (async () => {
      if (loading || !liff) return
      if (liff.isInClient() && !liff.isLoggedIn()) {
        await liff.login()
      }

      if (liff.isLoggedIn()) {
        setProfile(await liff.getProfile())
      }
    })()
  }, [liff, loading])

  return (
    <>
      {liff && (
        <LoginAndLogout loading={loading} label="LIFF" name={profile && profile.displayName} note={(!liff.isInClient() ? '外部ブラウザ' : '')} data={profile}>
          <MyButton onClick={() => liff.login()} disabled={liff.isLoggedIn()}>ログイン</MyButton>
          <MyButton onClick={() => liff.isLoggedIn() && liff.logout()} disabled={!liff.isLoggedIn()}>ログアウト</MyButton>
        </LoginAndLogout>
      )}
    </>
  )
}

function Auth0Status () {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    isLoading
  } = useAuth0()

  return (
    <LoginAndLogout loading={isLoading} label="Auth0" name={isAuthenticated && user.name} data={isAuthenticated && user}>
      <MyButton onClick={() => loginWithRedirect({ redirectUri: process.NEXT_PUBLIC_BASE_URL })} disabled={isAuthenticated}>ログイン</MyButton>
      <MyButton onClick={() => logout()} disabled={!isAuthenticated}>ログアウト</MyButton>
    </LoginAndLogout>
  )
}

export default function Home({ allPostsData }) {
  return (
    <Layout home>
      <Head>
        <title>{siteTitle}</title>
      </Head>
      <LiffProvider>
        <div className="flex flex-col">
          <LIFFStatus/>
          <Auth0Status/>
          <Link href="/healthchecks">
            <a className="text-center my-2 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded">診断をはじめる</a>
          </Link>
          <Link href="/admin">
            <a className="text-center my-2 px-4 py-2 bg-white hover:bg-blue-100 text-blue-500 border border-blue-800 font-bold rounded">管理コンソールを開く</a>
          </Link>
        </div>
      </LiffProvider>
    </Layout>
  )
}
